import React from 'react'

const formattedPlatformName = hostname => (
  <>
    {hostname?.toUpperCase()} Admin
  </>
)

const Header = () => {
  return (
    <a href='/' className='header-brand'>
      {formattedPlatformName(process.env.REACT_APP_APP_NAME)}
    </a>
  )
}

export { Header }
