import graphql from 'babel-plugin-relay/macro'
import React from 'react'
import { QueryRenderer } from 'react-relay'
import { Link } from 'react-router-dom'
import { Card } from 'tabler-react'

import md5 from 'md5'

import environment from '../../Environment'
import { Error, Loader } from '../../components'
import { imageResizerUrl } from '../../helpers'

const getUserName = user =>
  user.name ||
  `${[user.firstName, user.middleName, user.lastName]
    .filter(v => v)
    .join(' ')}` ||
  'Anonymous'

const Users = () => {
  const renderPictureUrl = ({ id, pictureUrl }) => {
    if (!pictureUrl) {
      // return <span class="avatar avatar-placeholder"></span>
      return (
        <span
          className='avatar'
          style={{
            backgroundImage: `url(https://www.gravatar.com/avatar/${md5(
              encodeURIComponent(id)
            )}.png?d=robohash&s=64)`
          }}
        />
      )
    }

    return (
      <span
        className='avatar'
        style={{
          backgroundImage: `url(${imageResizerUrl(pictureUrl, {
            width: 32,
            height: 32,
            fit: 'crop',
            dpr: 2
          })})`
        }}
      />
    )
  }

  const renderRow = node => {
    const user = node.node

    const formattedLocation =
      user.lastLatitude && user.lastLongitude
        ? `(${user.lastLatitude}, ${user.lastLongitude})`
        : '--'

    return (
      <tr key={user.id}>
        <td>{renderPictureUrl(user)}</td>
        <td>
          <Link to={`/users/${user.id}/edit`}>{getUserName(user)}</Link>
          <br />
          <span>{[user.phone, user.email].filter(v => v).join(', ')}</span>
        </td>
        <td className='d-none d-lg-table-cell'>{formattedLocation}</td>
        <td className='d-none d-lg-table-cell'>
          <span
            className={
              user.partner && user.partner.type !== 'SYSTEM'
                ? 'badge badge-dark'
                : 'badge badge-secondary'
            }
          >
            {(user.partner && user.partner.name) || 'None'}
          </span>
        </td>
        <td className='d-none d-lg-table-cell'>{user.platform}</td>
        <td className='d-none d-lg-table-cell'>{user.createdAt}</td>
      </tr>
    )
  }

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query UsersQuery {
          users: allUsers(orderBy: PRIMARY_KEY_ASC, first: 64) {
            totalCount
            edges {
              node {
                id
                createdAt
                name
                firstName
                middleName
                lastName
                phone
                email
                pictureUrl
                lastLatitude
                lastLongitude
                platform
                language
                flags
                partner: partnerByPartnerId {
                  id
                  name
                  type
                }
              }
            }
          }
        }
      `}
      variables={{}}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />

        return (
          <div className='my-3 my-md-5'>
            <div className='container'>
              <div className='row row-cards'>
                <div className='col-lg-12'>
                  <Card>
                    <div className='card-header'>
                      <h3 className='card-title'>
                        Users ({props.users.totalCount})
                      </h3>
                    </div>
                    <div className='table-responsive'>
                      <table className='table card-table table-striped table-vcenter'>
                        <thead>
                          <tr>
                            <th width='1'></th>
                            <th>Name</th>
                            <th className='d-none d-lg-table-cell'>Location</th>
                            <th className='d-none d-lg-table-cell'>Partner</th>
                            <th className='d-none d-lg-table-cell'>Platform</th>
                            <th className='d-none d-lg-table-cell'>Created</th>
                          </tr>
                        </thead>
                        <tbody>{props.users.edges.map(renderRow)}</tbody>
                      </table>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { Users }
