import { truncate } from 'lodash'
import React, { useCallback, useState } from 'react'
import { QueryRenderer } from 'react-relay'

import environment from '../../Environment'
import { Error, Loader } from '../../components'
import Config from '../../config'
import { CONVERSATIONS_QUERY } from './Conversations.graphql'

const config = Config(process.env.REACT_APP_ENV)

export const getNameFromUserlikeObject = ({ firstName, lastName }) =>
  [firstName, lastName].filter(Boolean).join(' ') || undefined

export const getUserName = (obj, fallbackName) => {
  if (!obj) return fallbackName

  const { name, firstName, lastName, email, phone } = obj

  return (
    getNameFromUserlikeObject({ firstName, lastName }) ||
    name ||
    email ||
    phone ||
    fallbackName
  )
}

const ConversationTypes = [
  'CHAT',
  'SMS',
  'EMAIL',
  'EVENT',
  'REMINDER',
  'BROADCAST',
  'ASSISTED'
]

const Conversations = () => {
  const [selectedType, setType] = useState(null)
  const [selectedPartnerId, setSelectedPartner] = useState(null)

  const renderFilterPartners = useCallback(
    partners => {
      return (
        <div className='dropdown'>
          <button
            type='button'
            className='btn btn-secondary dropdown-toggle'
            id='dropdownPartnersButton'
            aria-haspopup='true'
            aria-expanded='false'
            data-toggle='dropdown'
          >
            <i className='fe fe-filter mr-2'></i>
            {selectedPartnerId
              ? partners.find(p => p.id === selectedPartnerId)?.name || 'All'
              : 'Partners'}
          </button>
          <ul
            className='dropdown-menu'
            aria-labelledby='dropdownPartnerFilter'
            style={{
              maxHeight: '400px',
              overflowY: 'auto',
              width: '100%'
            }}
          >
            <li>
              <button
                type='button'
                className='dropdown-item'
                onClick={() => setSelectedPartner(null)} // Reset to 'All'
              >
                All
              </button>
            </li>
            {partners.map(p => (
              <li key={p.id}>
                <button
                  type='button'
                  className='dropdown-item'
                  onClick={() => setSelectedPartner(p.id)}
                >
                  {p.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )
    },
    [selectedPartnerId]
  )

  const renderFilterType = useCallback(() => {
    return (
      <div className='dropdown ml-2'>
        <button
          type='button'
          className='btn btn-secondary dropdown-toggle'
          data-toggle='dropdown'
        >
          <i className='fe fe-filter mr-2'></i>
          {selectedType ?? 'Type'}
        </button>
        <ul className='dropdown-menu' aria-labelledby='dropdownFilterButton'>
          <li>
            <button
              type='button'
              className='dropdown-item'
              onClick={() => setType(null)}
            >
              All
            </button>
          </li>
          {ConversationTypes.map(type => (
            <li key={type}>
              <button
                type='button'
                className='dropdown-item'
                onClick={() => setType(type)}
              >
                {type}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }, [selectedType])

  return (
    <QueryRenderer
      environment={environment}
      query={CONVERSATIONS_QUERY}
      variables={{
        type: selectedType,
        partnerId: selectedPartnerId
      }}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />

        const conversations =
          props.conversations?.edges?.map(ed => ed.node) ?? []

        const totalCount = props.conversations?.totalCount
        const currentCount = conversations.length

        const partners = props.partners?.edges?.map(ed => ed.node) ?? []

        return (
          <div className='my-3 my-md-5'>
            <div className='container'>
              <div className='row row-cards'>
                <div className='col-lg-12'>
                  <div className='card'>
                    <div className='card-header'>
                      <h3 className='card-title'>{`Conversations (${currentCount} / ${totalCount})`}</h3>
                      <div className='ml-auto'>
                        {renderFilterPartners(partners)}
                        {renderFilterType()}
                      </div>
                    </div>
                    <div className='table-responsive'>
                      <table className='table card-table table-striped table-vcenter'>
                        <thead>
                          <tr>
                            <th className='d-none d-lg-table-cell'>
                              Last Message
                            </th>
                            <th className='d-none d-lg-table-cell'>Sent</th>
                            <th className='d-none d-lg-table-cell'>Type</th>
                            {!selectedPartnerId && (
                              <th className='d-none d-lg-table-cell'>
                                Partner
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {conversations.map(conversation => {
                            const partner =
                              conversation.participants?.edges?.find(
                                e => e.participant.partner
                              )?.participant.partner
                            let lastMessage
                            let sender = 'Anonymous'
                            if (conversation.messages?.edges?.length > 0) {
                              lastMessage =
                                conversation.messages.edges[0].message
                              sender = getUserName(
                                lastMessage?.contact,
                                'Anonymous'
                              )
                            }

                            const url = `${config.conversationUrl}/c/${conversation.shortId}`
                            return (
                              <tr key={conversation.shortId || conversation.id}>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <a
                                      target='_blank'
                                      href={url}
                                      rel='noreferrer'
                                    >
                                      {conversation.title || 'Untitled'}
                                    </a>
                                    <span className='ml-1 badge bg-azure-lt'>
                                      {sender}
                                    </span>
                                  </div>
                                  <small>
                                    {truncate(lastMessage?.bodyExcerpt, {
                                      length: 128
                                    })}
                                  </small>
                                </td>
                                <td>{lastMessage?.createdAt}</td>
                                <td className='d-none d-lg-table-cell'>
                                  {conversation.type}
                                </td>
                                {!selectedPartnerId && (
                                  <td className='d-none d-lg-table-cell'>
                                    <a href={`partners/${partner?.id}/edit`}>
                                      {partner?.name}
                                    </a>
                                  </td>
                                )}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { Conversations }
