module.exports = (environment) => ({
  environment,
  appName: process.env.REACT_APP_APP_NAME,
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  imgixHost: process.env.REACT_APP_IMGIX_HOST,
  cloudImageHost: process.env.REACT_APP_CLOUDIMAGE_HOST,
  siteUrl: process.env.REACT_APP_SITE_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  conversationUrl: process.env.REACT_APP_CONVERSATION_URL,
});
