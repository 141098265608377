import React, { Component, useState } from 'react'
import { withCookies } from 'react-cookie'
import { NavLink, Route, Routes } from 'react-router-dom'
import 'tabler-react/dist/Tabler.css'

import './App.css'
import { Dashboard } from './components/Dashboard'
import { Header } from './components/Header'
import {
  Apps,
  Events,
  EventsEdit,
  EventsNew,
  EventsShow,
  Hubs,
  HubsEdit,
  Logs,
  Pages,
  PagesEdit,
  PagesNew,
  Partners,
  PartnersEdit,
  PartnersNew,
  PlacesEdit,
  Users,
  UsersEdit
} from './views'

import { authenticateByAdmin } from './api'
import { Conversations } from './views/conversations'

const Error = ({ error }) => {
  if (!error) return null

  return (
    <div
      class='alert alert-important alert-danger alert-dismissible'
      role='alert'
    >
      <div class='d-flex'>
        <div>{error}</div>
      </div>
    </div>
  )
}

const SignIn = withCookies(({ onSignUp, cookies, onSignIn }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleSignInPress = async e => {
    e.preventDefault()

    setError('')

    try {
      const {
        authenticateByAdmin: { jwt }
      } = await authenticateByAdmin({ email, password })
      if (jwt) {
        cookies.set('jwt', jwt, { path: '/' })
        onSignIn && onSignIn(jwt)
      } else {
        setError('Failed to authenticate')
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  return (
    <div className='page'>
      <div className='page-single'>
        <div className='container'>
          <div className='row'>
            <div className='col col-login mx-auto'>
              <form className='card' action='' method='post'>
                <div className='card-body p-6'>
                  <div className='card-title'>{`Login to your ${process.env.REACT_APP_APP_NAME?.toUpperCase()} account`}</div>
                  <Error error={error} />
                  <div className='form-group'>
                    <label className='form-label'>Email address</label>
                    <input
                      type='email'
                      className='form-control'
                      id='exampleInputEmail1'
                      aria-describedby='emailHelp'
                      value={email}
                      placeholder='Enter email'
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='form-label'>Password</label>
                    <input
                      type='password'
                      className='form-control'
                      id='exampleInputPassword1'
                      placeholder='Password'
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>
                  <div className='form-footer'>
                    <button
                      type='submit'
                      className='btn btn-primary btn-block'
                      onClick={handleSignInPress}
                    >
                      Sign in
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

class App extends Component {
  handleSignOutPress = async e => {
    e.preventDefault()

    this.props.cookies.remove('jwt', { path: '/' })
  }

  renderSignedOut () {
    // const [jwt, setJwt] = useState(this.props.cookies.get('jwt') || '')

    return <SignIn onSignIn={jwt => this.setState({ jwt })} />
  }

  renderSignedIn () {
    return (
      <div className='page'>
        <div className='page-main'>
          <div className='header py-4'>
            <div className='container'>
              <div className='d-flex'>
                <Header />
                <div className='d-flex order-lg-2 ml-auto'>
                  <div className='nav-item d-none d-md-flex' />
                </div>
                <a
                  href='/'
                  className='header-toggler d-lg-none ml-3 ml-lg-0'
                  data-toggle='collapse'
                  data-target='#headerMenuCollapse'
                >
                  <span className='header-toggler-icon' />
                </a>
              </div>
            </div>
          </div>
          <div
            className='header collapse d-lg-flex p-0'
            id='headerMenuCollapse'
          >
            <div className='container'>
              <div className='row align-items-center'>
                <div className='col-lg order-lg-first'>
                  <ul className='nav nav-tabs border-0 flex-column flex-lg-row'>
                    <li className='nav-item'>
                      <NavLink to='/' role='button' className='nav-link'>
                        <i className='fe fe-bar-chart' /> Dashboard
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink
                        to='/partners'
                        role='button'
                        className='nav-link'
                      >
                        <i className='fe fe-box' /> Partners
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink to='/hubs' role='button' className='nav-link'>
                        <i className='fe fe-map-pin' /> Hubs
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink to='/conversations' role='button' className='nav-link'>
                        <i className='fe fe-inbox' /> Conversations
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink to='/users' role='button' className='nav-link'>
                        <i className='fe fe-users' /> Users
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink to='/pages' role='button' className='nav-link'>
                        <i className='fe fe-file-text' /> Pages
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink to='/logs' role='button' className='nav-link'>
                        <i className='fe fe-list' /> Logs
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <a
                        href='/'
                        className='nav-link'
                        onClick={this.handleSignOutPress}
                      >
                        <i className='fe fe-power' /> Sign Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='/conversations' element={<Conversations />} />
            <Route path='/events' element={<Events />} />
            <Route path='/events/new' element={<EventsNew />} />
            <Route path='/events/:id' element={<EventsShow />} />
            <Route path='/events/:id/edit' element={<EventsEdit />} />
            <Route path='/partners' element={<Partners />} />
            <Route path='/partners/new' element={<PartnersNew />} />
            <Route path='/partners/:id/edit' element={<PartnersEdit />} />
            <Route path='/places/:id/edit' element={<PlacesEdit />} />
            <Route path='/hubs' element={<Hubs />} />
            <Route path='/hubs/:id/edit' element={<HubsEdit />} />
            <Route path='/apps' element={<Apps />} />
            <Route path='/users' element={<Users />} />
            <Route path='/users/:id/edit' element={<UsersEdit />} />
            <Route path='/pages' element={<Pages />} />
            <Route path='/pages/new' element={<PagesNew />} />
            <Route path='/pages/:id/edit' element={<PagesEdit />} />
            <Route path='/logs' element={<Logs />} />
          </Routes>
        </div>

        <div className='footer'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-6 col-md-3'>
                    <ul className='list-unstyled mb-0'>
                      <li>
                        <a href='https://app.buddy.works/familio'>Buddy</a>
                      </li>
                    </ul>
                  </div>
                  <div className='col-6 col-md-3'>
                    <ul className='list-unstyled mb-0'>
                      <li>
                        <a href='https://analytics.google.com/analytics/web/#/report/defaultid/a110082240w165969063p166436773/'>
                          Google Analytics
                        </a>
                      </li>
                      <li>
                        <a href='https://search.google.com/search-console?resource_id=https://app.familiohq.com/'>
                          Google Search Console
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='col-6 col-md-3'>
                    <ul className='list-unstyled mb-0'>
                      <li>
                        <a href='https://app.segment.com/familio/overview'>
                          Segment
                        </a>
                      </li>
                      <li>
                        <a href='https://mixpanel.com/report/1310320/dashboard'>
                          Mixpanel
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='col-6 col-md-3'>
                    <ul className='list-unstyled mb-0'>
                      <li>
                        <a href='https://play.google.com/apps/publish/?account=8540508397720599347&dev_acc=01872976970270454985#AppDashboardPlace:p=com.familifehq'>
                          Google Play
                        </a>
                      </li>
                      <li>
                        <a href='https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/ng/app/1268088544'>
                          iTunes Connect
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 mt-4 mt-lg-0'>
                Simple can be harder than complex: You have to work hard to get
                your thinking clean to make it simple.
              </div>
            </div>
          </div>
        </div>
        <footer className='footer'>
          <div className='container'>
            <div className='row align-items-center flex-row-reverse'>
              <div className='col-auto ml-lg-auto'>
                <div className='row align-items-center'>
                  <div className='col-auto'>
                    <ul className='list-inline list-inline-dots mb-0'>
                      <li className='list-inline-item'>
                        <a href='https://developers.familiohq.com/'>
                          Developers
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a href='https://github.com/familiohq'>Source code</a>
                      </li>
                      <li className='list-inline-item'>
                        <a href='https://familiohq.com/faq'>FAQ</a>
                      </li>
                    </ul>
                  </div>
                  <div className='col-auto'>
                    <ul className='list-inline list-inline-dots mb-0'>
                      <li className='list-inline-item'>
                        <a
                          href='https://itunes.apple.com/us/app/familio-family-inbox/id1268088544?mt=8'
                          className='btn btn-outline-primary btn-sm'
                        >
                          App Store
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a
                          href='https://play.google.com/store/apps/details?id=com.familifehq'
                          className='btn btn-outline-primary btn-sm'
                        >
                          Google Play
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-auto mt-3 mt-lg-0 text-center'>
                <span>{`Copyright© 2018-${new Date().getFullYear()}`}</span>{' '}
                <a href='https://familiohq.com/'>Familio</a>. All rights
                reserved.
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }

  render () {
    const { cookies } = this.props

    const parseToken = token => {
      if (!token || token.length === 0) return null
      // console.log('token', token)

      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = window.decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      )

      return JSON.parse(jsonPayload)
    }

    const token = cookies.get('jwt')
    const { role } = parseToken(token) || {}
    return role === 'admin' ? this.renderSignedIn() : this.renderSignedOut()
  }
}

export default withCookies(App)
